.messages {
  height: 100%;
  padding: 20px;
  padding-bottom: 0;
  overflow: auto;
  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
