//@import "~chat/styles/variables";
@import "~chatStylesSource/styles/variables";

.dialogs {
  &__search {
    padding: 2px 20px 20px 20px;

    .ant-input {
      background-color: #f7f8f9;
      border: 0;
      height: 38px;
      padding: 4px 11px 6px;
    }
  }

  &__nim-peer {
	position: relative;
  }
  &__nim-peer_small {
	width: 36px;
	height: 36px;
	box-sizing: content-box;
  }  
  &__nim-peer_big {
	width:50px;
	height: 50px;
  }  
  &__fl_l{
	float: left;
}


  
  &__nim-peer--photo-w
  {
	overflow: hidden;
	border-radius: 50%;
	
  }
  &__nim-peer--photo
  {
	margin-left: -2px;
	margin-bottom: -1px;
	overflow: hidden;
  
  }
  &__im_grid  
  {
	float:left;
	display: block;
  
  }
/*     */
&__ui_clean_list {
	margin:0;
	padding:0;
	display: block;
	list-style:none;

}
&__im-page--dcontent {
	margin-bottom: 15px;
	border-radius: 4px;
	overflow:visible;
	min-height: 100%;
	height: auto;
	box-sizing: border-box;

}


&__im-page--dcontent > li:hover {
  background: #dce1e6;
  
}



&__nim-dialog {
	padding-left: 20px;
	height: 71px;
	padding: 0 0 0 15px;
	box-sizing: border-box;
	display: block;
	width: 100%;
	cursor: pointer;
}
&__nim-dialog_current {
    background: rgba(174,183,194,0.12);
}
&__nim-dialog:hover {
	background: rgba(174,183,194,0.12);
}
&__nim-dialog_classic {
	padding-left: 20px;
	height: 71px;
}

&__nim-dialog--photo
{
	display: block;
	padding-right: 14px;
	padding-top: 11px;
	padding-bottom: 7px;
	float: left;
	border-color: #ffffff

}




&__nim-peer--photo-w	{
	overflow: hidden;
	border-radius: 50%;
}



&__nim-peer--photo {
	margin-left: -2px;
	margin-bottom: -1px;
	overflow: hidden;

}



&__im_grid {
	float:left;
	display: block;
}

&__im_grid > img {
/*	float:left;*/
	width: 50px;
	height: 50px;
        margin-left: 2px;
	margin-bottom: 1px;
	display: block;
	position: relative;
}

&__im_grid_small {
	float:left;
	display: block;
}

&__im_grid_small > img {
/*	float:left;*/
	width: 36px;
	height: 36px;
        margin-left: 2px;
	margin-bottom: 1px;
	display: block;
	position: relative;
}


&__nim-dialog--content {
	margin-left: 64px;
	padding-right: 15px;
	border-top: solid 1px #dce1e6;
	position: relative;
	display: block;
	text-decoration: none;
}


&__nim-dialog--cw {
	padding: 10px 0;
	height: 71px;
	box-sizing: border-box;	
	position: relative;


}
&__blind_label
{
	position: absolute;
	width: 1px;
	height:  1px;
	clip: rect(1px,1px,1px,1px);
	opacity: 0;
	overflow: hidden;
	
}

&__nim-dialog--date_wrapper {
	right: 15px;
	top: 14px;
	display: flex;
	position: absolute;

}

&__nim-dialog--star  {
	margin-right: 14px;
	border:0;
	width: 12px;
	height: 12px;
	vertical-align: middle;
	outline:0;
	display: none;
	z-index:1;
/*	background: url(/images/icons/im_actions.png?7) -4px -4px*/

}

&__nim-dialog--date {
	color: #818c99;
	font-size: 12.5px;
	opacity: 0.7;
}



&__nim-dialog--close {
	right: -5px;
	margin-top: 5px;
	border:0;
	padding:0;
	background: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20stroke%3D%22%2393A3BC%22%20stroke-width%3D%221.5%22%20d%3D%22M9%203L3%209M3%203l6%206%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E) center no-repeat;
	color: #2a5885;
	display: block;
	position:absolute;
	opacity: .7;
	width: 12px;
	height: 12px;
	z-index: 1;
        cursor: pointer;

}

&__im-dialog--close:hover {
	display: block;

}


&__nim-dialog--markre {
	margin-top:23px;
/*	background: url(/images/icons/im_actions.png?7) 0 -389px;*/
	right: -10px;
	border:0;
	padding:0;
	color: #2a5885;
/*если включить - разъедется*/
	display: none;

}

&__nim-dialog--name {
	margin-top: 3px;
	margin-bottom: 7px;
	height: 19px;
	font-size:13px;
	font-weight: 500;
	width: 100%;
	position: relative;

}

&__nim-dialog--name-w {
	padding-buttom: 1px;
	color: #000000;
	max-width: 70%;
	white-space: nowrap;
	display: inline-block;
	vertical-align: top;
	text-overflow: ellipsis;
	overflow: hidden;

}
&__image_status__status {
	vertical-align: -3px;
	margin-left: 4px;
	margin-right: 4px;
	font-size: 0;
	line-height: 0;
}

&__nim-dialog--verfifed {
	margin-top: 0;
	display: none;

}

&__nim-dialog--text-preview {
	max-width: 100%;
	width: 100%;
	margin-top: -2px;
	line-height: 16px;
	font-size: 13px;
	padding-bottom: 1px;
	overflow:hidden;
	color: #626d7a;
}
&__nim-dialog--text-preview_wrap_text {
	white-space: nowrap;
	text-overflow: ellipsis;
}

&__nim-dialog--preview {
	font-size:13px;
	padding-bottom: 1px;
	white-space: nowrap;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #626d7a;
	line-height: 16px;

}

&__nim-dialog--who {
	color: #818c99;

}


&__im-prebody {
	margin-right: 4px;
	display: inline-block;
	vertical-align: middle;

}
&__im-prebody > img {
	border-radius: 50%;
	background-color: #f0f2f5;
	overflow:hidden;
	width: 25px;
	height: 25px;
}

&__nim-dialog--inner-text {
	padding: 5px 0 5px 8px;
	border-radius: 3px;

	max-width: 100%;
	width: 90%;

	box-sizing: border-box;
	color: #626d7a;
	max-width: 403px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: top;

}
&__nim-dialog--inner-text_unreaded_other {
/* это определяет закраску фона при непрочитанных  */
	background: rgba(174,183,194,0.12);


}

&__nim-dialog--typing {
	display: none;

}

&__nim-dialog--typer-el {
	display: none;
	overflow: hidden;
	vertical-align: top;
	line-height: 16px;
	height: 16px;

}

&__nim-dialog--unread_container {
	bottom: 17px;
	display: flex;
	position: absolute;
	right: 15px;


}

&__nim-dialog--unread-badge_mention {
	background-image:  url(data:image/svg+xml;charset=utf-8,%3Csvg%20height%3D%2212%22%20width%3D%2212%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M6.165%2011c.764%200%201.443-.096%201.895-.246.367-.12.495-.28.495-.49%200-.21-.14-.342-.391-.342-.061%200-.14.006-.239.024-.55.132-.971.216-1.57.216-2.629%200-4.401-1.497-4.401-4.09%200-2.51%201.644-4.24%204.144-4.24%202.182%200%203.948%201.312%203.948%203.725%200%201.257-.464%202.138-1.143%202.138-.44%200-.678-.234-.678-.641v-2.97c0-.335-.196-.54-.526-.54-.324%200-.525.205-.525.54v.3h-.062c-.25-.534-.819-.84-1.491-.84-1.216%200-2.078%201.007-2.078%202.486%200%201.503.862%202.533%202.12%202.533.71%200%201.26-.347%201.529-.958h.061c.11.605.685.958%201.443.958C10.12%208.563%2011%207.275%2011%205.485%2011%202.755%208.952%201%206.11%201%203.09%201%201%202.982%201%206.036%201%209.15%203.164%2011%206.165%2011zm-.287-3.371c-.758%200-1.204-.6-1.204-1.593%200-.988.452-1.575%201.204-1.575.77%200%201.259.61%201.259%201.569%200%20.982-.495%201.599-1.26%201.599z%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E)

}

&__nim-dialog--unread-badge {
	display: none;
	background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20height%3D%2212%22%20width%3D%2212%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cpath%20d%3D%22M6.165%2011c.764%200%201.443-.096%201.895-.246.367-.12.495-.28.495-.49%200-.21-.14-.342-.391-.342-.061%200-.14.006-.239.024-.55.132-.971.216-1.57.216-2.629%200-4.401-1.497-4.401-4.09%200-2.51%201.644-4.24%204.144-4.24%202.182%200%203.948%201.312%203.948%203.725%200%201.257-.464%202.138-1.143%202.138-.44%200-.678-.234-.678-.641v-2.97c0-.335-.196-.54-.526-.54-.324%200-.525.205-.525.54v.3h-.062c-.25-.534-.819-.84-1.491-.84-1.216%200-2.078%201.007-2.078%202.486%200%201.503.862%202.533%202.12%202.533.71%200%201.26-.347%201.529-.958h.061c.11.605.685.958%201.443.958C10.12%208.563%2011%207.275%2011%205.485%2011%202.755%208.952%201%206.11%201%203.09%201%201%202.982%201%206.036%201%209.15%203.164%2011%206.165%2011zm-.287-3.371c-.758%200-1.204-.6-1.204-1.593%200-.988.452-1.575%201.204-1.575.77%200%201.259.61%201.259%201.569%200%20.982-.495%201.599-1.26%201.599z%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E);
	margin-right: 6px;
	border-radius: 50%;
	background: no-repeat center #5181b8;
	width:18px;
	height: 18px;
	box-sizing: content-box;


}



&__blind_label
{
	position: absolute;
	width: 1px;
	height:  1px;
	clip: rect(1px,1px,1px,1px);
	opacity: 0;
	overflow: hidden;
	
}

&__nim-dialog--unread {
	border-radius: 50%;
	padding: 0;
	background: rgba(147,173,200,.9);
	display: none;
	overflow:hidden;
	width: 18px;
	height: 18px;
	text-align: center;
	color: #ffffff;
	font-size: 11px;
	font-weight: 500;
	line-height: 19px;
	min-width: 6px;
	
	box-sizing: content-box;
	padding-left: 6px;
	padding-right: 6px;

}

&__nim-dialog--unread--visible {
display: block; 
background: #5181b8;
border-radius: 18px;
padding:0 6px;


}

&__tt_w  {
	cursor: default;
	box-shadow: 0 1px 3px rgba(0,0,0,0.0);
	box-sizing: content-box;
	z-index:1501;
	font-weight: 400;
	font-size: 12.5px;
	color: #222222;
	position: absolute;
	border: solid transparent;
	pointer-events: none;
	content: '';

}

&__tt_black  {
	margin-left:-13px;
	background: rgba(0,0,0,0.72);
	border:0;
	border-radius: 3px;
	
	color: rgb(34,34,34);
	
}
&__tocenter {
	left: 50%;
}
&__tt_down {
	bottom: 100%;
	margin-left:4px;

	border-width:5px;
	margin:0 ;
	bottom: 100%;

}
&__tt_text {
	padding: 4px 7px 5px;
	color: #ffffff;
	font-size: 1em;
	line-height: 1.154;
	white-space: nowrap;
	box-sizing: content-box;
	text-size-adjust: auto;
}



&__online {
	border-radius: 3px;
	background-size: cover;
	right: 4%;
	background-color: #fff;
	background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%227%22%20height%3D%2211%22%3E%3Cpath%20fill%3D%22%234bb34b%22%20fill-rule%3D%22evenodd%22%20d%3D%22M0%201.5C0%20.7.7%200%201.5%200h4C6.3%200%207%20.7%207%201.5v8c0%20.8-.7%201.5-1.5%201.5h-4A1.5%201.5%200%20010%209.5v-8zM1%202h5v6H1V2z%22%2F%3E%3C%2Fsvg%3E);

	bottom: 0;
	width:7px;
	height:11px;
	border: 2px solid #fff;
	content: '';
	position: absolute;


}
/*     */

  
  &__item {
    $item: &;
    display: flex;
    margin-bottom: 20px;
    color: $black;

    &:hover {
      background-color: #f3f7ff;
    }

    &--selected,
    &--selected:hover {
      background-color: #1890ff;
      color: #fff !important;
    }

    &-avatar {
      position: relative;
      flex: 0 0 40px;
      width: 40px;
	  background: green;
      height: 40px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &--online {
      #{$item}-avatar {
        &::before {
          display: block;
          position: absolute;
          bottom: -2px;
          right: 0;
          content: "";
          background-color: #00c980;
          border-radius: 30px;
          width: 13px;
          height: 13px;
          border: 3px solid #fff;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      &-top {
        display: flex;
        b {
          font-weight: 500;
          font-size: 14px;
          flex: 1;
          margin-bottom: 6px;
        }
        span {
          opacity: 0.5;
        }
      }
      &-bottom {
        position: relative;
        p {
          opacity: 0.7;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 220px;
          height: 1.2em;
          white-space: nowrap;
        }
        &-count {
          position: absolute;
          right: 0;
          bottom: 0;
          background: #f46b6b;
          color: #fff;
          font-weight: bold;
          width: 100%;
          min-width: 18px;
          max-width: 22px;
          height: 18px;
          border-radius: 30px;
          font-size: 11px;
          text-align: center;
          line-height: 17px;
          box-shadow: 0 0 0px 3px #fff;
        }
        .message__icon-readed {
          bottom: 0;
          left: auto;
          right: 0;
        }
      }
    }
  }
}
