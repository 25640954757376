.chatavatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  &--symbol {
    color: #fff;
    text-align: center;
    font-weight: 600;
    line-height: 38px;
    font-size: 18px;
  }
}
