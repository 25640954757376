@import '~chatStylesSource/styles/variables';


.message {
  $self: &;


  &__content {
    display: flex;
//    max-width: 440px; убрали чтоб на всю ширину растягивался
    margin-bottom: 20px;
    position: relative;

    &:hover {
      #{$self}__icon-actions {
        display: block;
      }
    }
  }
/*                                                                                   */

&__att_im_msg_media_doc {
	margin-right: 8px;
	margin-top: 4px;
	padding-bottom: 4px;
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
}
__att_media_desc {
	padding-bottom: 0;
}
&__att_media_desc__doc {
	margin-top:1px;
	padding: 0;
}

&__att_im_msg_media_doc_block {
	margin-top:1px;
	display: block;
	width: 350px;
}
&__att_page_doc_row {
	padding-bottom: 2px;
	
	font-size: 12.5px;
	color: #2a5885;
	line-height: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow:hidden;
}
&__att_page_doc_icon {
	float: left;
	margin-right: 8px;
	border-radius: 3px;
	background: url(https://st.vk.com/images/icons/doc_icons.png) no-repeat;
	text-align: center;
	
	display: block;
	width: 30px;
	height: 30px;
	overflow: hidden;
	text-overflow:ellipsis;
	box-sizing: border-box;
	

}
&__att_page_doc_icon1 {
	background-position: left 0;
}
&__att_page_doc_title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #2a5885;
	text-weight: 500;
	line-height: 16px;
}
&__att_page_doc_description_row {
	margin-left: 38px;
	pointer-events: none;
}
&__att_page_doc_size	{
	display: inline-block;
	color: #939393;
	pointer-events: none;
}
&__att_page_doc_app {
	color: #939393;
	display: inline-block;
	pointer-events: all;
	
}

&__img_page_post_sized_thumbs
{
	padding: 10px 0 4px;
	box-sizing: content-box;
}

&__img_im_msg_media {
	box-sizing: content-box;
	
}

&__img_page_post_thumb_wrap 
{
	margin: 0 5px 5px 0;
	position: relative;
	display: block;
	overflow: hidden;
	box-sizing:content-box;
}
&__img_image_cover  
{
	background-position: 50%;
}
&__img_page_post_thumb_last_column 
{
	margin-right: 0;
}
&__img_page_post_thumb_last_row
{
	margin-bottom: 0;
}


  &__im-mess-stack {
    max-width: 100%;
    min-width: 550px;	
    position: relative;
// этот ховер отрисовывает backgorund и показывает кнопки удаления у сообщения
  &:hover {
    background-color: aliceblue; 
	  .__im-mess--reply {
		visibility: visible; // при наведении на  message__im-mess-stack--mess в классе replyy меняется видимость. вложенный hover
	}

  }	

}
  &__im-mess-stack_unreaded {
		background: #dce1e6;
}


  &__im-mess-stack--photo {
	left: 36px;
	position: absolute;
	top: 8px;
	z-index:2;
	box-sizing: content-box;
}

  &__im-mess-stack--pname {
	left: 82px;
	display: block;
	z-index: 2;
	font-size: 12.5px;
	font-weight: 700;
	position: absolute;
	top: 10px;
}

  &__im-mess-stack--lnk	{
	max-width: 240px;
	white-space: nowrap;
	overflow:hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: top;
	line-height: 1.23;
    color: #2a5885
}

  &__im-mess-stack--tools
{
	margin-left: 4px;
	font-size: 12px;
	z-index:2;
	line-height: 1.4;
	font-weight: 400;
	line-height: 16.8px;
	text-align: left;
}


  &__ui_clean_list
	{
	margin: 0;
	padding: 0;
	display: block;
	list-style: none;

}

  &__im-mess
	{
	position: relative;
	padding: 6px 30px 7px 0;

}
  &__im-mess--actions
{
	float: right;
	margin: 0 5px 0 -4px;

}

  &__im-mess--forward
{
	vertical-align: top;
	width: 24px;
	height: 24px;
	transform: scale(-1,-1);
        background: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20stroke%3D%22%2393A3BC%22%20stroke-width%3D%221.5%22%20d%3D%22M9%203L3%209M3%203l6%206%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E) center no-repeat
}
  &__im-mess--reply
{
	display: inline-block;
	vertical-align: top;
	visibility: hidden;
	width: 24px;
	height: 24px;
        cursor: pointer;
        background: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20fill%3D%22none%22%3E%0A%20%20%3Cpath%20stroke%3D%22%2393A3BC%22%20stroke-width%3D%221.5%22%20d%3D%22M9%203L3%209M3%203l6%206%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E) center no-repeat;
	&:hover {
//	 	visibility: visible;
	}

}
  &__im-mess--edit
	{
	vertical-align: top;
	width: 24px;
	height: 24px;

}

  &__im-mess--fav
	{
	display: inline-block;
	vertical-align: top;
	width: 24px;
	height: 24px;
	

}
  &__fl_l{
	float: left;
}
  &__im-mess--text
	{
	padding-top: 21px;
	margin-left: 78px;
	margin: 0 49px 0 86px;
	line-height: 18px;
	word-wrap: break-word;
	/* будет длинный непрерывный текс переносить адекватно. и ширина при наличии картинки вроде как стала адеватна*/
	width:0;
	min-width: calc(100% - 86px);

}
  &__blind_label
{
	position: absolute;
	width: 1px;
	height:  1px;
	clip: rect(1px,1px,1px,1px);
	opacity: 0;
	overflow: hidden;
	
}

  &__im-mess--blind-read
{
	display: none;
}



/*                                                                                   */




  &__avatar {
    display: flex;
    flex-direction: column-reverse;
    .avatar {
      border-radius: 50px;
      width: 33px;
      height: 33px;
      margin-right: 13px;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 31px;
    }
  }

  &__bubble {
    background: $blue;
    box-shadow: 0px 5px 5px rgba(54, 116, 255, 0.196733);
    border-radius: 12px 12px 12px 0px;
    padding: 15px;
    margin-bottom: 8px;
  }

  &__date {
    font-size: 12px;
    opacity: 0.5;
  }

  &__text {
    color: #fff;
    line-height: 20px;
  }

  &__attachments {
    display: flex;
    margin-bottom: 5px;
    order: 1;
    &-item {
      $item: &;
      margin-right: 5px;
      cursor: pointer;
      position: relative;

      img {
//        width: 45px;   убрали в надежде, что будет все пропорционально и не шире родителя
//        height: 45px;  убрали в надежде, что будет все пропорционально и не шире родителя
        border-radius: 8px;
      }

      &:hover {
        #{$item}-overlay {
          opacity: 1;
        }
      }
	   
      .anticon-eye {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
// добавляло анимацию и закраску для блока аттачмента
//      &-overlay {
//        opacity: 0;
//        position: absolute;
 //       left: 0;
 //       top: 0;
 //       width: 100%;
 //       height: 100%;
 //       background-color: rgba(0, 0, 0, 0.3);
 //       transition: opacity 0.15s ease-in-out;
 //       border-radius: 8px;
 //     }
       &-fileSize {
		text-align: 'left' 
	   }
    }
  }

  &__icon {
    &-readed {
      position: absolute;
      bottom: 22px;
      left: -25px;

      &--no {
        left: -20px;
      }
    }
    &-closebutton {
      bottom: 22px;
      left: -25px;
    }
    &-actions {
      display: none;
      transform: rotate(90deg);
      position: absolute;
      left: -31px;
      top: -5px;
    }
  }

  &--is-audio {
    audio {
      display: none;
    }
    #{$self}__bubble {
      width: 280px;
      position: relative;
      overflow: hidden;
    }
    #{$self}__audio {
      padding: 15px;
      background-color: #3674ff;
      border-radius: 12px 12px 0px 12px;
      width: 250px;
      overflow: hidden;
      position: relative;
      &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }
      &-btn {
        button {
          display: flex;
          cursor: pointer;
          border: 0;
          width: 28px;
          height: 28px;
          border-radius: 30px;
          background: #0f3997;
          img {
            height: 13px;
            position: relative;
            left: 1px;
          }
        }
      }
      &-duration {
        color: #fff;
        font-weight: 500;
        opacity: 0.5;
        font-size: 12px;
      }
      &-progress {
        left: 0;
        top: 0;
        position: absolute;
        background-color: #418fff;
        height: 100%;
        transition: width 0.3s ease;
      }
    }
  }

  &--isme {
    display: flex;
    flex-direction: row-reverse;

    .avatar {
      margin-left: 13px;
      margin-right: 0;
    }

    #{$self}__content {
      margin-bottom: 15px;
      flex-direction: row-reverse;
    }

    #{$self}__avatar {
      img {
        margin-right: 0;
        margin-left: 13px;
      }
    }

    #{$self}__info {
	/* 2020 убрал. иначе блок когда сообщение рисуется слева
      display: flex;
	  */
      align-items: center;
      text-align: left;
    }

    #{$self}__bubble {
      background: #ffffff;
      border: 1px solid #ececec;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.0220444);
      border-radius: 12px 12px 0px 12px;
      order: 2;
      #{$self}__text {
        color: $black;
      }
    }
  }

  &--is-typing {
    #{$self}__bubble {
      background-color: $message-typing-blue;
      box-shadow: 0px 5px 5px rgba(54, 116, 255, 0.04);
      padding: 15px 18px;
      margin-bottom: 0;
    }
  }

  &--image {
    #{$self}__attachments {
      &-item {
        img {
          width: 30px; //менял
          height: 30px; //менял
        }
      }
    }
  }

  &__typing {
    span {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: $message-typing-circle;
      display: inline-block;
      margin-right: 8px;
      animation: 0.8s typingAnimation infinite ease-out 0.1s;

      &:nth-of-type(2) {
        animation: 0.8s typingAnimation infinite ease-out 0.2s;
      }
      &:nth-of-type(3) {
        animation: 0.8s typingAnimation infinite ease-out 0.3s;
        margin-right: 0;
      }
    }
  }
}

@keyframes typingAnimation {
  from {
    opacity: 0.2;
    transform: translateY(0px);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-1.5px);
  }
  to {
    opacity: 1;
    transform: translateY(-1px);
  }
}



.attachment {
/*border: 1px solid #ccc;*/
display: block;
/* вот это в итоге образало справа длинный текст */
overflow: hidden;
width: 350px;
  height: 30px;
font-size: 12.5px;
font-weight: 400;
line-height: 14px;
text-align: left;
text-overflow: ellipsis;
white-space: nowrap;

-webkit-font-smoothing: subpixel-antialiased;
list-style-image:none;
list-style-position: outside;
list-style-type: none;
overflow-wrap: break-word;

}



.attachmenticonlink {
  float: left;
  background: url("https://vk.com/images/icons/doc_icons.png") no-repeat;
  box-sizing: border-box;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 8px;

  
}
.attachmentdoclink {
  overflow-x: hidden;
  overflow-y: hidden;
cursor: pointer;

font-size: 12.5px;
font-weight: 500;
line-height: 16px;
text-align: left;
text-decoration-color: rgb(42,88,133);
text-decoration-line: none;
text-decoration-style: solid;
text-decoration-thickness: auto;
text-overflow: ellipsis;
white-space: nowrap;

-webkit-font-smoothing: subpixel-antialiased;
list-style-image: none;
list-style-position: outside;
list-style-type: none;
overflow-wrap: break-word;

font-family: -apple-system,BlinkMacSystemFont,'Roboto','Helvetica Neue',Geneva,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai",sans-serif,arial,Tahoma,verdana;


}

.attachmentdocfilesize {
 margin-left: 38px;
cursor: pointer;
}

.attachmentfilesize {
      display: inline-block;
font-family: -apple-system,BlinkMacSystemFont,'Roboto','Helvetica Neue',Geneva,"Noto Sans Armenian","Noto Sans Bengali","Noto Sans Cherokee","Noto Sans Devanagari","Noto Sans Ethiopic","Noto Sans Georgian","Noto Sans Hebrew","Noto Sans Kannada","Noto Sans Khmer","Noto Sans Lao","Noto Sans Osmanya","Noto Sans Tamil","Noto Sans Telugu","Noto Sans Thai",sans-serif,arial,Tahoma,verdana;
font-size: 12.5px;
font-weight: 400;
line-height: 14px;
text-align: left;
white-space: nowrap;

color: rgb(147,147,147);
cursor: pointer;
	
-webkit-font-smoothing: subpixel-antialiased;
list-style-image: none;
list-style-position: outside;
list-style-type: none;
overflow-wrap: break-word;
pointer-events: none;
}








