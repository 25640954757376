.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__top {
    text-align: center;
    margin-bottom: 50px;
    h2 {
      font-size: 28px;
      margin-bottom: 5px;
    }
    p {
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.1px;
      opacity: 0.5;
    }
  }

  &__register-link {
    text-align: center;
    display: block;
    font-size: 16px;
    letter-spacing: 0.1px;
    color: #adadad;
  }

  &__success-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 400px;
    svg {
      width: 48px;
      height: 48px;
    }
    h2 {
      margin-top: 10px;
    }
    p {
      color: #adadad;
    }
  }

  .button {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    width: 100%;
  }

  .block {
    width: 430px;
  }
}

.verify-block {
  &__loading {
    text-align: center;
  }
}
