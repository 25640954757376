.add-dialog-form {
  .ant-form-item {
    margin-bottom: 0;
    &-label {
      line-height: inherit !important;
    }
  }
}


.sidebar-close-icon {
//  position: absolute; in other case at the right side
  right: 0.25rem;
  top: 0.25rem;
  font-size: 1.25rem;
  z-index: 1;
  cursor: pointer;
  visibility: hidden; }


@media (max-width: 767.98px) {
  .sidebar-close-icon {
    visibility: visible;
    top: 15px;
    right: 0;
    padding: 0.65rem;
    line-height: 1; }
 }
