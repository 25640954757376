@import "~reset-css";   
@import "~antd-v3/dist/antd-v3.css";

//@import "layouts/chat.scss";

body {
  background-color: #f4f7fd;
}

body,
html,
#root,
.wrapper {
  height: 100%;
  color: #202020;
//  display: flex; TODO removed because in this case width not authochange
  position: relative;
  overflow: hidden; // Required in apps
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}

* {
  outline: none;
}

.ant-btn-circle {
  border: 0;
  box-shadow: none;
  color: #202020 !important;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }

  &:after {
    display: none;
  }
}
